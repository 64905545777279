import request from '@/utils/request'

// 社会缴费列表
export const socialPaymentListAPI = (data) => {
  return request({
    url: '/socialpayList',
    method: 'POST',
    data
  })
}

// 添加/编辑缴费项目
export const paymentItemAPI = (data) => {
  return request({
    url: '/addSocialpay',
    method: 'POST',
    data
  })
}

// 详情
export const paymentDetailsAPI = (data) => {
  return request({
    url: '/socialpayInfo',
    method: 'POST',
    data
  })
}

// 删除
export const moneyDeleteAPI = (params) => {
  return request({
    url: '/socialpayDel',
    method: 'GET',
    params
  })
}

// 详情
export const detailAPI = (params) => {
  return request({
    url: '/payorderlist',
    method: 'GET',
    params
  })
}

// // 添加
// export const paymentItemsAddAPI = (data) => {
//   return request({
//     url: '/payorderadd',
//     method: 'POST',
//     data
//   })
// }
// 添加
export const paymentItemsAddAPI = (data) => {
  return request({
    url: '/twopayorderadd',
    method: 'POST',
    data
  })
}

// 删除
export const payorderDelAPI = (params) => {
  return request({
    url: '/payorderDel',
    method: 'GET',
    params
  })
}

// 导出
export const reportSocpayAPI = (params) => {
  return request({
    url: '/report_socpay',
    method: 'POST',
    params
  })
}

// 导入
// export const importSocpayAPI = (data) => {
//   return request({
//     url: '/socialpay_import',
//     method: 'POST',
//     data
//   })
// }
export const importSocpayAPI = (data) => {
  return request({
    url: '/twosocialpay_import',
    method: 'POST',
    data
  })
}

// 置为已退费
// export const refundAPI = (params) => {
//   return request({
//     url: '/socpayRefund',
//     method: 'GET',
//     params
//   })
// }

// 置为已退费
export const refundAPI = (params) => {
  return request({
    url: '/twosocpayRefund',
    method: 'GET',
    params
  })
}
// 微信置为已退费
export const wxPutAsARefundFeeAPI = (params) => {
  return request({
    url: '/soctui',
    method: 'GET',
    params
  })
}
