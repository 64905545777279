<template>
  <div>
    <el-drawer v-model="orderShow" title="添加订单" :before-close="handleClose">
      <el-form
        ref="addPaymentRef"
        :model="addPaymentForm"
        :rules="rules"
        label-width="100px"
        status-icon
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addPaymentForm.name" />
        </el-form-item>
        <el-form-item label="凭证号" prop="cardno">
          <el-input v-model="addPaymentForm.cardno" />
        </el-form-item>

        <el-form-item label="缴费金额" prop="amt">
          <el-input v-model="addPaymentForm.amt" />
        </el-form-item>

        <el-form-item label="缴款事项">
          <el-input v-model="addPaymentForm.pay_matters" />
        </el-form-item>

        <el-form-item label="订单编号">
          <el-input disabled v-model="addPaymentForm.number" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm(addPaymentRef)"
            >确认</el-button
          >
          <el-button @click="resetForm(addPaymentRef)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { paymentItemsAddAPI } from '@/api/social-payment'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },
  number: {
    type: String
  },
  onDetail: {
    type: Function
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const orderShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

const handleClose = (done) => {
  orderShow.value = false
}

watch(
  () => props.number,
  (val) => {
    addPaymentForm.value.number = val
  }
)

/** 表单 S */
const addPaymentForm = ref({
  id: '',
  name: '',
  cardno: '',
  amt: '',
  number: props.number
})

const rules = ref({
  name: [{ required: true, message: '必须输入缴费姓名', trigger: 'blur' }],
  cardno: [{ required: true, message: '必须输入缴费凭证号', trigger: 'blur' }],
  amt: [{ required: true, message: '必须输入缴费金额', trigger: 'blur' }],
  pay_matters: [
    { required: true, message: '必须输入缴款事项', trigger: 'blur' }
  ]
})

const addPaymentRef = ref(null)
const submitForm = async (formEl) => {
  console.log(formEl)
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      await paymentItemsAddAPI(addPaymentForm.value)
      ElMessage.success('缴费名单人员-添加成功 !')
      orderShow.value = false
      props.onDetail()
      addPaymentForm.value = {
        id: '',
        name: '',
        cardno: '',
        number: props.number
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  orderShow.value = false
  addPaymentForm.value = {
    id: '',
    name: '',
    cardno: '',
    number: props.number
  }
}

/** 表单 E */
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
</style>
