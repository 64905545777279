import request from '@/utils/request'

// 缴费订单列表
export const payOrderList = (params) => {
  return request({
    url: '/orderList',
    method: 'GET',
    params
  })
}

// 导出
export const payTheFeesexportAPI = (params) => {
  return request({
    url: '/order_report',
    method: 'GET',
    params
  })
}

// 置为已退费
export const putAsARefundFeeAPI = (params) => {
  return request({
    url: '/refundState',
    method: 'GET',
    params
  })
}

// 微信置为已退费
export const wxPutAsARefundFeeAPI = (params) => {
  return request({
    url: '/soctui',
    method: 'GET',
    params
  })
}

// 取消已退费
export const cancelTheRefundFeeAPI = (params) => {
  return request({
    url: '/cancel_refund',
    method: 'GET',
    params
  })
}

// 订单明细
export const orderDetailsAPI = (data) => {
  return request({
    url: '/orderDetail',
    method: 'POST',
    data
  })
}

// 发放物品
export const issueItemsAPI = (params) => {
  return request({
    url: '/grantItem',
    method: 'GET',
    params
  })
}

// 批量发放
export const batchDistributionAPI = (data) => {
  return request({
    url: '/grantallItem',
    method: 'POST',
    data
  })
}

/** 物品发放 */
// 列表
export const listOfItemsAPI = (params) => {
  return request({
    url: '/orderextList',
    method: 'GET',
    params
  })
}

// 批量发放
export const batchDistributionOfItemsAPI = (data) => {
  return request({
    url: '/orderDetail',
    method: 'POST',
    data
  })
}
//
// 退费申请
export const refundlistAPI = (params) => {
  return request({
    url: '/refundlist',
    method: 'GET',
    params
  })
}

// 审核
export const refundExamineAPI = (params) => {
  return request({
    url: '/process',
    method: 'POST',
    params
  })
}
// 医务室缴费
export const infirmaryAPI = (params) => {
  return request({
    url: '/infirmaryList',
    method: 'GET',
    params
  })
}

// 医务室缴费详情
export const infirmaryInfoAPI = (params) => {
  return request({
    url: '/infirmaryInfo',
    method: 'GET',
    params
  })
}

// 导出
export const infirmaryReportAPI = (params) => {
  return request({
    url: '/infirmaryReport',
    method: 'GET',
    params
  })
}

// 毕业生缴费
export const graduatepayListAPI = (params) => {
  return request({
    url: '/graduatepayList',
    method: 'GET',
    params
  })
}

// 毕业生缴费添加
export const addGraduatepayAPI = (params) => {
  return request({
    url: '/addGraduatepay',
    method: 'POST',
    params
  })
}

// 毕业生缴费删除
export const graduatepayDelAPI = (params) => {
  return request({
    url: '/graduatepayDel',
    method: 'GET',
    params
  })
}

// 毕业生缴费详情
export const graduatepayInfoAPI = (params) => {
  return request({
    url: '/graduatepayInfo',
    method: 'GET',
    params
  })
}

// 导入毕业生缴费
export const graduatepayImportAPI = (dataset) => {
  return request({
    url: '/graduatepayImport',
    method: 'POST',
    data: {
      dataset
    }
  })
}

// 导出
export const reportGradupayAPI = (params) => {
  return request({
    url: '/reportGradupay',
    method: 'GET',
    params
  })
}
// 导出
export const reportRefundAPI = (params) => {
  return request({
    url: '/reportRefund',
    method: 'GET',
    params
  })
}

// 毕业生缴费修改
export const saveGraduatepayAPI = (data) => {
  return request({
    url: '/saveGraduatepay',
    method: 'POST',
    data
  })
}

// 学生缴费汇总
export const orderAllAPI = (params) => {
  return request({
    url: '/orderAll',
    method: 'POST',
    params
  })
}

// 异常数据列表
export const graduaErrorAPI = (params) => {
  return request({
    url: '/graduaError',
    method: 'GET',
    params
  })
}

// 异常数据导出
export const graduareErrorAPI = (params) => {
  return request({
    url: '/graduareError',
    method: 'GET',
    params
  })
}

// 毕业生缴费批量修改
export const batchEditAPI = (data) => {
  return request({
    url: '/batchEdit',
    method: 'POST',
    data
  })
}
