<template>
  <div>
    <el-drawer v-model="detailShow" title="详情" :before-close="handleClose">
      <el-form :model="addPaymentForm" label-width="110px" status-icon>
        <el-form-item label="姓名">
          <el-input disabled v-model="addPaymentForm.name" />
        </el-form-item>
        <el-form-item label="凭证号">
          <el-input disabled v-model="addPaymentForm.cardno" />
        </el-form-item>
        <el-form-item label="微信支付订单号">
          <el-input disabled v-model="addPaymentForm.wxorderid" />
        </el-form-item>

        <el-form-item label="订单编号">
          <el-input disabled v-model="addPaymentForm.number" />
        </el-form-item>
        <el-form-item label="缴费时间">
          <el-date-picker
            disabled
            v-model="addPaymentForm.pay_time"
            type="datetime"
            placeholder="请选择日期时间"
            format="YYYY/MM/DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>

        <el-form-item label="添加时间">
          <el-date-picker
            disabled
            v-model="addPaymentForm.created_time"
            type="datetime"
            placeholder="请选择日期时间"
            format="YYYY/MM/DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>

        <el-form-item label="缴费状态">
          <!-- <el-input disabled v-model="addPaymentForm.payState" /> -->
          <el-select disabled v-model="addPaymentForm.payState">
            <el-option label="未交费" :value="0" />
            <el-option label="已缴费" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="退款状态">
          <el-select disabled v-model="addPaymentForm.refundState">
            <el-option label="未退款" :value="0" />
            <el-option label="已退款" :value="1" />
          </el-select>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },
  moneyItem: {
    type: Object
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const detailShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

const handleClose = (done) => {
  detailShow.value = false
}

watch(
  () => props.moneyItem,
  (val) => {
    addPaymentForm.value = val
    console.log(val)
  },
  {
    deep: true
  }
)

/** 表单 S */
const addPaymentForm = ref({
  name: '',
  number: '',
  cardno: '',
  wxorderid: '',
  pay_time: '',
  created_time: '',
  payState: '',
  refundState: ''
})

/** 表单 E */
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
</style>
